import { MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

import { MenuButton } from 'components'

import { ActionsType } from './actions.types'

import { usePermission } from 'hooks'

import constants from 'constants/index'

const Actions = ({ dataProcess }: ActionsType) => {
  const navigate = useNavigate()
  const { isPermitted } = usePermission()

  const { APPROVED_STATUS, REVIEW_LIA_CLIENT_STATUS, REVIEW_LIA_DPO_STATUS } =
    constants.legitimateInterest.STATUSES_LIA

  const showEditItem =
    [APPROVED_STATUS, REVIEW_LIA_CLIENT_STATUS, REVIEW_LIA_DPO_STATUS].includes(
      dataProcess.statusId
    ) && isPermitted(constants.permissionTags.LEGITIMATE_INTEREST.MANAGE)

  const navigateTo = (route: string) =>
    navigate(
      reverse(route, {
        dataProcessId: dataProcess.id,
      })
    )

  const handleRedirectToEdit = () => {
    if (
      [APPROVED_STATUS, REVIEW_LIA_DPO_STATUS].includes(dataProcess.statusId)
    ) {
      return navigateTo(constants.routes.app.legitimateInterest.reviewDpo)
    }

    navigateTo(constants.routes.app.legitimateInterest.reviewClient)
  }

  const handleRedirectToShow = () => {
    navigateTo(constants.routes.app.legitimateInterest.show)
  }

  return (
    <MenuButton id={`menu-button-lia-${dataProcess.id}`}>
      {showEditItem && (
        <MenuItem onClick={handleRedirectToEdit}>Editar</MenuItem>
      )}

      <MenuItem onClick={handleRedirectToShow}>Visualizar</MenuItem>
    </MenuButton>
  )
}

export default Actions
