import { colors } from '@mui/material'

import palette from 'theme/palette'

// STATUSES IDS
const NEW_TICKET_ID = 1
const PENDING_TICKET_ID = 2
const FINISHED_TICKET_ID = 3
const CANCELED_TICKET_ID = 4
const WAITING_CONFIRMATION_TICKET_ID = 5
const AWAITING_CONFIRMATION_LEGITIMACY_ID = 6
const AWAITING_CONFIRMATION_IDENTITY_ID = 7
const AWAITING_COMPANY_REPLY_ID = 8
const AWAITING_REPLY_VALIDATION_ID = 9
const REPLIED_ID = 10
const ALL_TICKETS_ID = null
const MY_SOLICITATION_ID = 0
const REOPEN_TICKET_ID = 11

// STATUSES LABELS
const NEW_TICKET = 'Novo'
const PENDING_TICKET = 'Pendente'
const FINISHED_TICKET = 'Concluído'
const REOPEN_TICKET = 'Reaberto'
const CANCELED_TICKET = 'Cancelado'
const WAITING_CONFIRMATION_TICKET = 'Aguardando Confirmação'
const AWAITING_CONFIRMATION_LEGITIMACY = 'Aguardando Aprovação da Solicitação'
const AWAITING_CONFIRMATION_IDENTITY = 'Pendente de Confirmação de Identidade'
const AWAITING_COMPANY_REPLY = 'Aguardando Resposta da Empresa'
const AWAITING_REPLY_VALIDATION = 'Validar Resposta'
const REPLIED = 'Respondido'

// TICKET MESSAGE KINDS IDS
const INTERNAL_MESSAGE_ID = 1
const EXTERNAL_MESSAGE_ID = 2
const CLIENT_MESSAGE_ID = 3

const ALL_MESSAGE_ID = [
  INTERNAL_MESSAGE_ID,
  EXTERNAL_MESSAGE_ID,
  CLIENT_MESSAGE_ID,
] as const

// TICKET MESSAGE KINDS LABELS
const INTERNAL_MESSAGE_TYPE = 'Interno'
const EXTERNAL_MESSAGE_TYPE = 'Externo'
const CLIENT_MESSAGE_TYPE = 'Solicitante'

// TICKET KINDS
const TITULAR_TICKET_KIND = 'titular'
const ANPD_TICKET_KIND = 'anpd'
const JURIDIC_TICKET_KIND = 'juridic'
const SECURITY_TICKET_KIND = 'security'
const ADVISOR_JURIDIC_TICKET_KIND = 'advisor_juridic'
const ADVISOR_SECURITY_TICKET_KIND = 'advisor_security'

const DATA_SHARING = 'Compartilhamento de dados pessoais'
const CHILDREN_DATA_TREATMENT =
  'Tratamentos de dados pessoais de crianças e adolescentes'
const WHATSAPP_PHONE_DATA_TREATMENT =
  'Tratamento de dados pessoais via WhatsApp/telefone'
const THIRD_PARTY_DOCUMENT_HANDLING =
  'Retirada/recebimento de documentos por terceiros'
const PLATFORM_MODELS = 'Modelos da plataforma'
const CONSENT = 'Consentimento'
const SECURITY_CAMERAS = 'Câmeras de segurança'
const PRIVACY_POLICY = 'Política de Privacidade/Aviso de Privacidade'
const PLATFORM_USABILITY =
  'Usabilidade da plataforma (mapeamento, emissão de relatórios)'
const ANOTHER = 'Outra'

const STATUSES_IDS = [
  NEW_TICKET_ID,
  PENDING_TICKET_ID,
  FINISHED_TICKET_ID,
  CANCELED_TICKET_ID,
  WAITING_CONFIRMATION_TICKET_ID,
  AWAITING_CONFIRMATION_LEGITIMACY_ID,
  AWAITING_CONFIRMATION_IDENTITY_ID,
  AWAITING_COMPANY_REPLY_ID,
  AWAITING_REPLY_VALIDATION_ID,
  REPLIED_ID,
  REOPEN_TICKET_ID,
] as const

const ALL_TICKETS_KIND = [
  TITULAR_TICKET_KIND,
  ANPD_TICKET_KIND,
  JURIDIC_TICKET_KIND,
  SECURITY_TICKET_KIND,
] as const

// TICKET TABS

const PRIVACY_PORTAL_TICKET_STATUS_TABS = [
  {
    id: NEW_TICKET_ID,
    tabName: 'NOVOS',
  },
  {
    id: WAITING_CONFIRMATION_TICKET_ID,
    tabName: 'AGUARDANDO CONFIRMAÇÃO',
  },
  {
    id: PENDING_TICKET_ID,
    tabName: 'PENDENTES',
  },
  {
    id: AWAITING_COMPANY_REPLY_ID,
    tabName: 'AGUARDANDO EMPRESA',
  },
  {
    id: REPLIED_ID,
    tabName: 'RESPONDIDOS',
  },
  {
    id: FINISHED_TICKET_ID,
    tabName: 'FINALIZADOS',
  },
  {
    id: ALL_TICKETS_ID,
    tabName: 'TODOS',
  },
]

const TICKET_CHAT_STATUS_TABS = [
  {
    id: NEW_TICKET_ID,
    tabName: 'NOVOS',
  },
  {
    id: PENDING_TICKET_ID,
    tabName: 'PENDENTES',
  },
  {
    id: FINISHED_TICKET_ID,
    tabName: 'FINALIZADOS',
  },
  {
    id: CANCELED_TICKET_ID,
    tabName: 'CANCELADOS',
  },
  {
    id: REOPEN_TICKET_ID,
    tabName: 'REABERTOS',
  },
  {
    id: ALL_TICKETS_ID,
    tabName: 'TODOS',
  },
  {
    id: MY_SOLICITATION_ID,
    tabName: 'MINHAS SOLICITAÇÕES',
  },
]

const TICKET_STATUS_FILTER = [
  {
    id: NEW_TICKET_ID,
    name: 'Novo',
  },
  {
    id: PENDING_TICKET_ID,
    name: 'Pendente',
  },
  {
    id: FINISHED_TICKET_ID,
    name: 'Concluído',
  },
  {
    id: REOPEN_TICKET_ID,
    name: 'Reaberto',
  },
  {
    id: CANCELED_TICKET_ID,
    name: 'Cancelado',
  },
]

const STATUSES_BY_TAB: { [key: number]: number[] } = {
  [NEW_TICKET_ID]: [NEW_TICKET_ID],
  [WAITING_CONFIRMATION_TICKET_ID]: [WAITING_CONFIRMATION_TICKET_ID],
  [PENDING_TICKET_ID]: [
    PENDING_TICKET_ID,
    AWAITING_CONFIRMATION_LEGITIMACY_ID,
    AWAITING_REPLY_VALIDATION_ID,
  ],
  [AWAITING_COMPANY_REPLY_ID]: [
    AWAITING_CONFIRMATION_IDENTITY_ID,
    AWAITING_COMPANY_REPLY_ID,
  ],
  [REPLIED_ID]: [REPLIED_ID],
  [FINISHED_TICKET_ID]: [FINISHED_TICKET_ID, CANCELED_TICKET_ID],
}

const PRIVACY_PORTAL_TICKET_STATUS_OPTIONS = [
  {
    id: NEW_TICKET_ID,
    name: NEW_TICKET,
    disabled: true,
  },
  {
    id: PENDING_TICKET_ID,
    name: PENDING_TICKET,
    disabled: false,
  },
  {
    id: FINISHED_TICKET_ID,
    name: FINISHED_TICKET,
    disabled: false,
  },
  {
    id: CANCELED_TICKET_ID,
    name: CANCELED_TICKET,
    disabled: false,
  },
  {
    id: WAITING_CONFIRMATION_TICKET_ID,
    name: WAITING_CONFIRMATION_TICKET,
    disabled: true,
  },
  {
    id: AWAITING_CONFIRMATION_LEGITIMACY_ID,
    name: AWAITING_CONFIRMATION_LEGITIMACY,
    disabled: true,
  },
  {
    id: AWAITING_CONFIRMATION_IDENTITY_ID,
    name: AWAITING_CONFIRMATION_IDENTITY,
    disabled: true,
  },
  {
    id: AWAITING_COMPANY_REPLY_ID,
    name: AWAITING_COMPANY_REPLY,
    disabled: true,
  },
  {
    id: AWAITING_REPLY_VALIDATION_ID,
    name: AWAITING_REPLY_VALIDATION,
    disabled: true,
  },
  {
    id: REPLIED_ID,
    name: REPLIED,
    disabled: false,
  },
]

const TICKET_CHAT_STATUS_OPTIONS = [
  {
    id: NEW_TICKET_ID,
    name: NEW_TICKET,
    disabled: true,
  },
  {
    id: PENDING_TICKET_ID,
    name: PENDING_TICKET,
    disabled: false,
  },
  {
    id: FINISHED_TICKET_ID,
    name: FINISHED_TICKET,
    disabled: false,
  },
  {
    id: REOPEN_TICKET_ID,
    name: REOPEN_TICKET,
    disabled: true,
  },
  {
    id: CANCELED_TICKET_ID,
    name: CANCELED_TICKET,
    disabled: false,
  },
]

const TICKET_CHAT_TABS = [
  { id: EXTERNAL_MESSAGE_ID, kind: EXTERNAL_MESSAGE_TYPE },
  { id: INTERNAL_MESSAGE_ID, kind: INTERNAL_MESSAGE_TYPE },
]

const STATUSES_ID_DISABLE_ACTIONS = [
  FINISHED_TICKET_ID,
  CANCELED_TICKET_ID,
  WAITING_CONFIRMATION_TICKET_ID,
]

const CHIP_STATUS_BACKGROUND_COLOR = {
  [NEW_TICKET_ID]: palette.support.main,
  [PENDING_TICKET_ID]: palette.info.main,
  [FINISHED_TICKET_ID]: palette.success.main,
  [CANCELED_TICKET_ID]: palette.error.main,
  [WAITING_CONFIRMATION_TICKET_ID]: palette.ticketStatus.waitingConfirmation,
  [AWAITING_CONFIRMATION_LEGITIMACY_ID]:
    palette.ticketStatus.awaitingConfirmationLegitimacy,
  [AWAITING_CONFIRMATION_IDENTITY_ID]:
    palette.ticketStatus.awaitingConfirmationIdentity,
  [AWAITING_COMPANY_REPLY_ID]: palette.ticketStatus.awaitingCompanyReply,
  [AWAITING_REPLY_VALIDATION_ID]: palette.ticketStatus.awaitingReplyValidation,
  [REPLIED_ID]: palette.ticketStatus.replied,
  [REOPEN_TICKET_ID]: palette.ticketStatus.replied,
}

const MESSAGE_TYPE = {
  [INTERNAL_MESSAGE_ID]: INTERNAL_MESSAGE_TYPE,
  [EXTERNAL_MESSAGE_ID]: EXTERNAL_MESSAGE_TYPE,
  [CLIENT_MESSAGE_ID]: CLIENT_MESSAGE_TYPE,
}

const MESSAGE_TYPE_BOX_COLOR = {
  [INTERNAL_MESSAGE_ID]: colors.orange[50],
  [EXTERNAL_MESSAGE_ID]: colors.blue[50],
  [CLIENT_MESSAGE_ID]: colors.blue[50],
}

const MESSAGE_TYPE_ICON_COLOR = {
  [INTERNAL_MESSAGE_ID]: colors.orange[700],
  [EXTERNAL_MESSAGE_ID]: colors.blue[700],
  [CLIENT_MESSAGE_ID]: colors.blue[700],
}

const TICKET_KIND_TRANSLATION = {
  [TITULAR_TICKET_KIND]: 'Titular de dados',
  [ANPD_TICKET_KIND]: 'ANPD',
  [JURIDIC_TICKET_KIND]: 'Jurídico',
  [SECURITY_TICKET_KIND]: 'Segurança da informação',
  [ADVISOR_JURIDIC_TICKET_KIND]: 'Jurídico',
  [ADVISOR_SECURITY_TICKET_KIND]: 'Segurança da informação',
}

const LEGAL_TICKET_TYPES = [
  { id: JURIDIC_TICKET_KIND, name: 'Jurídico' },
  { id: SECURITY_TICKET_KIND, name: 'Segurança da informação' },
]

const TICKET_TITLES = [
  {
    id: DATA_SHARING,
    name: DATA_SHARING,
  },
  {
    id: CHILDREN_DATA_TREATMENT,
    name: CHILDREN_DATA_TREATMENT,
  },
  {
    id: WHATSAPP_PHONE_DATA_TREATMENT,
    name: WHATSAPP_PHONE_DATA_TREATMENT,
  },
  {
    id: THIRD_PARTY_DOCUMENT_HANDLING,
    name: THIRD_PARTY_DOCUMENT_HANDLING,
  },
  {
    id: PLATFORM_MODELS,
    name: PLATFORM_MODELS,
  },
  {
    id: CONSENT,
    name: CONSENT,
  },
  {
    id: SECURITY_CAMERAS,
    name: SECURITY_CAMERAS,
  },
  {
    id: PRIVACY_POLICY,
    name: PRIVACY_POLICY,
  },
  {
    id: PLATFORM_USABILITY,
    name: PLATFORM_USABILITY,
  },
  {
    id: ANOTHER,
    name: ANOTHER,
  },
]

export type MessageTicketIdType = (typeof ALL_MESSAGE_ID)[number]
export type StatusTicketIdType = (typeof STATUSES_IDS)[number]
export type TicketKindType = (typeof ALL_TICKETS_KIND)[number]

export default {
  ALL_TICKETS_ID,
  ALL_TICKETS_KIND,
  ANPD_TICKET_KIND,
  AWAITING_COMPANY_REPLY_ID,
  AWAITING_COMPANY_REPLY,
  AWAITING_CONFIRMATION_IDENTITY_ID,
  AWAITING_CONFIRMATION_IDENTITY,
  AWAITING_CONFIRMATION_LEGITIMACY_ID,
  AWAITING_CONFIRMATION_LEGITIMACY,
  AWAITING_REPLY_VALIDATION_ID,
  AWAITING_REPLY_VALIDATION,
  CANCELED_TICKET_ID,
  CANCELED_TICKET,
  CHIP_STATUS_BACKGROUND_COLOR,
  CLIENT_MESSAGE_ID,
  CLIENT_MESSAGE_TYPE,
  EXTERNAL_MESSAGE_ID,
  EXTERNAL_MESSAGE_TYPE,
  FINISHED_TICKET_ID,
  FINISHED_TICKET,
  INTERNAL_MESSAGE_ID,
  INTERNAL_MESSAGE_TYPE,
  JURIDIC_TICKET_KIND,
  LEGAL_TICKET_TYPES,
  MESSAGE_TYPE_BOX_COLOR,
  MESSAGE_TYPE_ICON_COLOR,
  MESSAGE_TYPE,
  MY_SOLICITATION_ID,
  NEW_TICKET_ID,
  NEW_TICKET,
  PENDING_TICKET_ID,
  PENDING_TICKET,
  PRIVACY_PORTAL_TICKET_STATUS_OPTIONS,
  PRIVACY_PORTAL_TICKET_STATUS_TABS,
  REPLIED_ID,
  REPLIED,
  SECURITY_TICKET_KIND,
  STATUSES_BY_TAB,
  STATUSES_ID_DISABLE_ACTIONS,
  STATUSES_IDS,
  TICKET_CHAT_STATUS_OPTIONS,
  TICKET_CHAT_STATUS_TABS,
  TICKET_CHAT_TABS,
  TICKET_KIND_TRANSLATION,
  TICKET_STATUS_FILTER,
  TICKET_TITLES,
  TITULAR_TICKET_KIND,
  WAITING_CONFIRMATION_TICKET_ID,
  WAITING_CONFIRMATION_TICKET,
}
