import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDown, ArrowUp } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import Table from 'components/Table'

import { TableChangesCollapseType } from './logsTable.types'

import { formatters, logs } from 'helpers'
import { LogsType } from 'types/logs.types'

const TableChangesCollapse = ({
  changes,
  registerType,
  auditableName,
  comment,
  open,
  showRegisterSession,
}: TableChangesCollapseType) => {
  const { t } = useTranslation()

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid
              item
              xl={showRegisterSession ? 6 : 12}
              lg={showRegisterSession ? 6 : 12}
              md={showRegisterSession ? 6 : 12}
              sm={12}
              xs={12}
            >
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  p={1}
                  flexWrap="wrap"
                >
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="body1" fontWeight={500}>
                      Tipo de registro
                    </Typography>
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="body1">
                      {t(`registerTypes.${registerType}`)}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {showRegisterSession && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Paper>
                  <Box
                    display="flex"
                    justifyContent="center"
                    p={1}
                    flexWrap="wrap"
                  >
                    <Box width="100%" display="flex" justifyContent="center">
                      <Typography variant="body1" fontWeight={500}>
                        Registro
                      </Typography>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      maxWidth={400}
                      px={2}
                    >
                      <Typography
                        variant="body1"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        noWrap
                        whiteSpace="pre-wrap"
                      >
                        {auditableName ? auditableName : '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box my={2} ml={2} marginY={5}>
          <Typography
            variant="body1"
            fontWeight={500}
            gutterBottom
            component={Box}
          >
            {comment ? 'Mensagem' : 'Alterações'}
          </Typography>
        </Box>
        {comment ? (
          <Box ml={2}>
            <Typography variant="body1">{comment}</Typography>
          </Box>
        ) : (
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="33%">Informação</TableCell>
                <TableCell width="33%">Antes</TableCell>
                <TableCell width="33%">Depois</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changes.map((change, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      noWrap
                      whiteSpace="pre-wrap"
                    >
                      {t(`${registerType}.${change[0]}`)}&nbsp;
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      noWrap
                      whiteSpace="pre-wrap"
                    >
                      {logs.formattedValue(
                        change[0],
                        change[1][0],
                        registerType
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      noWrap
                      whiteSpace="pre-wrap"
                    >
                      {logs.formattedValue(
                        change[0],
                        change[1][1],
                        registerType
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Collapse>
  )
}

const TableRowLog = ({
  log,
  showRegisterSession,
  ...rest
}: {
  key?: number
  log: LogsType
  showRegisterSession?: boolean
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <TableRow {...rest}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <ArrowUp /> : <ArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {formatters.date.ptBrFormatDate(log?.createdAt)}
          </Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {formatters.date.ptBrFormatHours(log?.createdAt)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {log?.author && log?.author?.name}
          </Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {log?.author && log?.author?.email}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3}>
          <TableChangesCollapse
            changes={log.changes}
            registerType={log.auditableType}
            auditableName={log.auditableName}
            comment={log?.comment}
            open={open}
            showRegisterSession={showRegisterSession}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const LogsTable = ({
  logs,
  showRegisterSession,
}: {
  logs: LogsType[]
  showRegisterSession?: boolean
}) => {
  return (
    <Box>
      <PerfectScrollbar>
        <Table size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="10%" />
              <TableCell width="40%">Data</TableCell>
              <TableCell width="50%">Autor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRowLog
                key={index}
                log={log}
                showRegisterSession={showRegisterSession}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default LogsTable
