import { createContext } from 'react'
import {
  OrganizationType,
  OrganizationUserType,
} from 'types/organization.types'

type OrganizationContextType = {
  currentOrganization?: OrganizationType
  currentOrganizationUser?: OrganizationUserType
  loadedOrganizations?: boolean
  loadOrganization: (organization?: OrganizationType) => void
  loadOrganizations: () => Promise<void>
  organizations: OrganizationType[]
  availableOrganizations: OrganizationType[]
  availableObserverOrganizations: OrganizationType[]
  availableDpoOrganizations: OrganizationType[]
  availableAuthorDpoOrganizations: OrganizationType[]
}

const contextValues: OrganizationContextType = {
  loadOrganization: (organization?: OrganizationType) => organization,
  loadOrganizations: async () => {},
  organizations: [],
  availableOrganizations: [],
  availableObserverOrganizations: [],
  availableDpoOrganizations: [],
  availableAuthorDpoOrganizations: [],
  loadedOrganizations: false,
}

const ProviderContext = createContext(contextValues)

export default ProviderContext
