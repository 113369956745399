import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Button } from '@mui/material'

import {
  DialogLogs,
  ListPageStructure,
  LoadingFeedback,
  PageHead,
} from 'components'
import { GeneralInformations, QuestionsDpo } from './components'

import { useFetch, useSnackbar } from 'hooks'

import { LogsType } from 'types/logs.types'

import service from 'service'
import constants from 'constants/index'
import { formatters } from 'helpers'

const LegitimateInterestShow = () => {
  const [openHistory, setOpenHistory] = useState(false)
  const [logsLegitimateInterest, setLogsLegitimateInterest] = useState<
    LogsType[]
  >([])
  const [logsLoading, setLogsLoading] = useState(false)

  const { dataProcessId } = useParams()
  const { snackbar } = useSnackbar()

  const { response, loading } = useFetch(
    service.dponet.dataProcesses.showWithLia,
    {
      dataProcessId,
    }
  )

  const { DPO } = constants.legitimateInterest.ROLES

  const dataProcess = response?.data?.dataProcess
  const answersClientApproved = dataProcess?.liaAnswers

  const {
    response: responseLiaQuestionsWithAnswers,
    loading: loadingLiaQuestionsWithAnswers,
    refresh,
  } = useFetch(service.dponet.liaQuestion.getWithAnswers, {
    params: { dataProcessId },
  })

  const liaQuestionsWithAnswers =
    responseLiaQuestionsWithAnswers?.data?.liaQuestions

  const {
    response: responseOnlyLiaQuestions,
    loading: loadingOnlyLiaQuestions,
  } = useFetch(service.dponet.liaQuestion.get, {
    params: {
      dataProcessId,
      role: DPO,
      perPage: 40,
      order: 'lia_questions.order asc',
    },
  })

  const onlyQuestions = responseOnlyLiaQuestions?.data?.liaQuestions

  const getLogsLegitimateInterest = async () => {
    try {
      setLogsLoading(true)
      const response = await service.dponet.dataProcesses.getLogs({
        dataProcessId,
      })
      setLogsLegitimateInterest(response?.data?.dponetAudits)
      setOpenHistory(true)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao buscar os logs.',
        variant: 'error',
      })
    } finally {
      setLogsLoading(false)
    }
  }

  const handleCloseHistory = () => setOpenHistory(false)

  return (
    <ListPageStructure>
      <LoadingFeedback
        open={
          loading ||
          loadingLiaQuestionsWithAnswers ||
          loadingOnlyLiaQuestions ||
          logsLoading
        }
      />
      <PageHead
        name="Visualizar Legítimo Interesse (LIA)"
        actionButton={
          <Button
            variant="outlined"
            onClick={() => getLogsLegitimateInterest()}
          >
            HISTÓRICO
          </Button>
        }
      />
      <GeneralInformations dataProcess={dataProcess} />
      {!loading &&
        !loadingLiaQuestionsWithAnswers &&
        !loadingOnlyLiaQuestions &&
        !isEmpty(dataProcess) && (
          <QuestionsDpo
            onlyQuestions={onlyQuestions}
            liaQuestionsWithAnswers={liaQuestionsWithAnswers}
            dataProcess={dataProcess}
            companyCity={dataProcess?.companyCity}
            createdAt={dataProcess?.createdAt}
            refresh={refresh}
            answersClientApproved={answersClientApproved}
            show
          />
        )}
      <DialogLogs
        open={openHistory}
        onClose={handleCloseHistory}
        logs={logsLegitimateInterest}
        showRegisterSession
      />
    </ListPageStructure>
  )
}

export default LegitimateInterestShow
