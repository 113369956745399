import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Grid, Box, Typography, colors, Container } from '@mui/material'
import { isEmpty } from 'lodash'

import { AllowObserver, LoadingFeedback, PageName } from 'components'
import {
  TicketHead,
  TicketMessage,
  TicketTextBox,
  TicketInfoTable,
  AiTicketGenerative,
} from './components'
import { TicketAssigned } from 'components/Tickets'

import { formatters } from 'helpers'

import {
  useAuth,
  useFetch,
  useOrganization,
  usePermission,
  useSnackbar,
} from 'hooks'

import { UpdateParamsType } from './ticket.types'
import { TicketType } from 'types/ticket.types'
import { TicketMessageType } from 'types/ticketMessages.types'

import service from 'service'
import schema, { TicketContentFormType } from './schema'
import logoNotFound from 'images/not-found-ticket.svg'
import constants from 'constants/index'
import { LogsType } from 'types/logs.types'

const TicketContent = () => {
  const [activeButton, setActiveButton] = useState(
    constants.tickets.INTERNAL_MESSAGE_ID
  )
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [responsibleButtonGroup, setResponsibleButtonGroup] = useState<
    number | undefined
  >()
  const [statusButtonGroup, setStatusButtonGroup] = useState<
    number | undefined
  >()

  const { user } = useAuth()
  const { ticketId } = useParams()
  const { snackbar } = useSnackbar()
  const { isPermitted } = usePermission()
  const { currentOrganization } = useOrganization()
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<TicketContentFormType>({
    resolver: yupResolver(schema),
  })

  const {
    response: responseTicket,
    loading: loadingTicket,
    refresh: refreshTicket,
  } = useFetch(service.dponet.ticket.show, {
    ticketId,
  })

  const {
    response: responseMessages,
    loading: loadingMessages,
    refresh: refreshMessages,
  } = useFetch(service.dponet.ticket.getMessages, {
    ticketId,
  })

  const {
    response: responseOrganizationUsers,
    loading: loadingOrganizationUsers,
    refresh: refreshOrganizationUsers,
  } = useFetch(service.dponet.organizationUser.get, {
    organizationId: currentOrganization?.id,
    params: { perPage: 999 },
  })

  const {
    response: responseLogs,
    loading: loadingLogs,
    refresh: refreshLogs,
  } = useFetch(service.dponet.ticket.getLogs, {
    ticketId,
  })

  const ticket: TicketType = responseTicket?.data?.ticket ?? []

  const ticketMessages: TicketMessageType[] =
    responseMessages?.data?.ticketMessages ?? []

  const organizationUsers =
    responseOrganizationUsers?.data?.organizationUsers ?? []

  const solicitationLogs: LogsType[] = responseLogs?.data?.dponetAudits ?? []

  const isPermittedManageTicket = isPermitted(
    constants.permissionTags.SOLICITATION_TICKET.MANAGE
  )

  const refresh = () => {
    refreshTicket()
    refreshMessages()
    refreshOrganizationUsers()
    refreshLogs()
  }

  const onSubmit = async (data: TicketContentFormType) => {
    try {
      const updateParams = mountUpdateParams()

      if (
        updateParams.statusId === constants.tickets.REPLIED_ID &&
        activeButton !== constants.tickets.EXTERNAL_MESSAGE_ID
      ) {
        snackbar.open({
          message:
            'Para alterar o status para respondido, selecione a mensagem externa',
          variant: 'error',
        })
        return
      }

      const postMessageResponse = await service?.dponet?.ticket?.postMessages({
        ticketId,
        ticketMessages: {
          kind: activeButton,
          content: data?.content,
          attachments: Object.values(selectedFiles || {}),
        },
      })

      if (!isEmpty(updateParams)) {
        await service?.dponet?.ticket.update({
          ticketId,
          ticket: {
            ticketMessageId: postMessageResponse?.data?.ticket_message?.id,
            ...updateParams,
          },
        })
      }

      snackbar.open({
        message: 'Mensagem enviada com sucesso!',
        variant: 'success',
      })

      reset()
      refresh()

      setSelectedFiles([])
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const mountUpdateParams = () => {
    const updateParams: UpdateParamsType = {}

    if (
      responsibleButtonGroup &&
      responsibleButtonGroup != ticket?.responsible?.id
    ) {
      updateParams.responsibleId = responsibleButtonGroup
    }

    if (statusButtonGroup && statusButtonGroup != ticket?.status?.id) {
      updateParams.statusId = statusButtonGroup
    }

    return updateParams
  }

  const isMessageAvailable =
    !loadingMessages &&
    !loadingTicket &&
    (!isEmpty(ticketMessages) || ticket?.content)

  useEffect(() => {
    if (ticket) {
      if (
        isEmpty(responsibleButtonGroup) ||
        responsibleButtonGroup !== ticket?.responsible?.id
      ) {
        setResponsibleButtonGroup(ticket?.responsible?.id)
      }

      if (
        isEmpty(statusButtonGroup) ||
        statusButtonGroup !== ticket?.status?.id
      ) {
        setStatusButtonGroup(ticket?.status?.id)
      }
    }
  }, [ticket, ticket?.status?.id])

  const isNotObserverOrganization =
    currentOrganization?.kind !== constants.organizations.KIND_OBSERVER

  return (
    <Container component={Box} paddingY={12}>
      <PageName name="Ticket" onlyPageTitle />
      <LoadingFeedback open={loadingMessages} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!loadingTicket && (
            <TicketHead
              ticket={ticket}
              ticketMessages={ticketMessages}
              logs={solicitationLogs}
              loadingLogs={loadingLogs}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display="flex" flexDirection="column" height="100%">
            {!isEmpty(ticket) && <AiTicketGenerative ticket={ticket} />}
            <PerfectScrollbar>
              <Box height="100%">
                {isMessageAvailable ? (
                  <TicketMessage
                    ticket={ticket}
                    ticketMessages={ticketMessages}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    gap={5}
                    justifyContent="center"
                    height="100%"
                  >
                    <img height={150} src={logoNotFound} />
                    <Typography variant="h5" color={colors.grey[500]}>
                      Nenhuma interação neste ticket
                    </Typography>
                  </Box>
                )}
              </Box>
            </PerfectScrollbar>
            {user &&
              !loadingTicket &&
              isPermittedManageTicket &&
              isNotObserverOrganization && (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  id="ticket-content-form"
                >
                  <TicketTextBox
                    refresh={refresh}
                    ticket={ticket}
                    user={user}
                    control={control}
                    register={register}
                    error={errors.content}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    statusButtonGroup={statusButtonGroup}
                    setStatusButtonGroup={setStatusButtonGroup}
                  />
                </form>
              )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box display="flex" flexDirection="column" gap={6}>
            {!loadingOrganizationUsers && !loadingTicket && (
              <TicketAssigned
                ticket={ticket}
                organizationUsers={organizationUsers}
                responsibleButtonGroup={responsibleButtonGroup}
                setResponsibleButtonGroup={setResponsibleButtonGroup}
                statusButtonGroup={statusButtonGroup}
              />
            )}

            {!loadingTicket && (
              <>
                <AllowObserver
                  entity={ticket}
                  isTicket={true}
                  refresh={refreshTicket}
                />
                <TicketInfoTable
                  ticket={ticket}
                  ticketMessages={ticketMessages}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TicketContent
