import { useEffect, useState } from 'react'

import { OrganizationContext } from 'contexts'

import { OrganizationProviderType } from './organizationProvider.types'
import {
  OrganizationType,
  OrganizationUserType,
} from 'types/organization.types'

import { useAuth } from 'hooks'

import service from 'service'
import { organizations as organizationsHelper } from 'helpers'

const OrganizationProvider = ({ children }: OrganizationProviderType) => {
  const [organizations, setOrganizations] = useState<OrganizationType[]>([])
  const [currentOrganization, setCurrentOrganization] = useState<
    OrganizationType | undefined
  >()
  const [currentOrganizationUser, setCurrentOrganizationUser] =
    useState<OrganizationUserType>({})
  const [loadedOrganizations, setLoadedOrganizations] = useState<boolean>(false)

  const { user } = useAuth()

  const availableOrganizations =
    organizationsHelper.onlyActiveOrganizations(organizations)
  const availableObserverOrganizations =
    organizationsHelper.observerOrganizations(availableOrganizations)
  const availableDpoOrganizations = organizationsHelper.dpoOrganizations(
    availableOrganizations
  )
  const availableAuthorDpoOrganizations =
    organizationsHelper.authorOrganizations(availableDpoOrganizations, user)

  const loadOrganizations = async () => {
    setLoadedOrganizations(false)

    const response = await service.dponet.organization.get({})
    const organizationsResponse = response?.data?.organizations

    setOrganizations(organizationsResponse)
    setInitialOrganization(organizationsResponse)
    setLoadedOrganizations(true)
  }

  const setInitialOrganization = async (
    organizations: OrganizationType[] | undefined
  ) => {
    if (!loadedOrganizations || !organizations || organizations.length === 0)
      return

    const localOrganizationId = service.dponet.auth.getOrganization()
    const localOrganization = organizationsHelper.selectOrganization(
      organizations,
      localOrganizationId
    )

    if (localOrganization && (await loadOrganization(localOrganization))) return

    loadOrganization(organizations[0])
  }

  const loadOrganization = async (organization?: OrganizationType) => {
    try {
      if (shouldNotLoadOrganization(organization?.id)) {
        service.dponet.auth.removeOrganization()
        return false
      }

      setLoadedOrganizations(false)

      const responseToken =
        await service.dponet.organization.selectOrganization({
          organizationId: organization?.id,
        })

      service.dponet.auth.setOrganization(organization?.id)
      service.dponet.auth.setToken(responseToken?.data?.authToken)

      const responseIdentify = await service.dponet.user.identify()

      setCurrentOrganization(organization)
      setCurrentOrganizationUser(responseIdentify?.data?.organizationUser)

      setLoadedOrganizations(true)
      return true
    } catch (error) {
      setLoadedOrganizations(true)
      console.error(error)
      return false
    }
  }

  const shouldNotLoadOrganization = (organizationId: number | undefined) => {
    const isAvailableOrganization = availableOrganizations.some(
      (availableOrganization) => {
        return availableOrganization.id === organizationId
      }
    )

    const alreadyLoadedOrganization =
      currentOrganization !== undefined &&
      organizationId === currentOrganization?.id

    return !isAvailableOrganization && !alreadyLoadedOrganization
  }

  useEffect(() => {
    if (service.dponet.auth.isAuthenticated()) loadOrganizations()
  }, [])

  useEffect(() => {
    setInitialOrganization(availableOrganizations)
  }, [organizations])

  return (
    <OrganizationContext.Provider
      value={{
        currentOrganization,
        currentOrganizationUser,
        loadedOrganizations,
        loadOrganization,
        loadOrganizations,
        organizations,
        availableOrganizations,
        availableObserverOrganizations,
        availableDpoOrganizations,
        availableAuthorDpoOrganizations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
