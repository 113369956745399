import { Button, Typography } from '@mui/material'

import { BasicDialog } from 'components'

import { PermissionAccessClientPortalDialogType } from './permissionAccessClientPortalDialog.types'

const PermissionAccessClientPortalDialog = ({
  open,
  onClose,
}: PermissionAccessClientPortalDialogType) => {
  return (
    <BasicDialog
      open={open}
      title="Inativar Usuário"
      onClose={onClose}
      dialogActions={
        <Button variant="contained" color="error" onClick={onClose}>
          FECHAR
        </Button>
      }
    >
      <Typography variant="body1">
        Este usuário não possui permissão para listar empresas. Acesse
        &quot;Perfis de Usuários&quot; e habilite a permissão necessária.
      </Typography>
    </BasicDialog>
  )
}

export default PermissionAccessClientPortalDialog
