// import { useState } from 'react'
import { Eye as EyeIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import {
  Box,
  IconButton,
  MenuItem,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
} from '@mui/material'

import {
  MenuButton,
  MUI,
  Permitted,
  Table as TableComponent,
  TableRow,
} from 'components'

import { formatters, tasks as taskHelper } from 'helpers'

import { TaskTableType } from './taskTable.types'

import permissionTagsConstants from 'constants/permissionTags'
import tasksConstants from 'constants/tasks'

const TaskTable = ({
  tasks,
  loading,
  handleOpenView,
  handleOpenEdit,
  handleDestroy,
  getLogsTask,
}: TaskTableType) => {
  return (
    <TableContainer>
      <TableComponent aria-label="listagem de atividades">
        <TableHead>
          <TableRow>
            <MUI.TableCell align="left">ID</MUI.TableCell>
            <MUI.TableCell align="left">Empresa</MUI.TableCell>
            <MUI.TableCell align="left">Tarefa</MUI.TableCell>
            <MUI.TableCell align="left">Responsável</MUI.TableCell>
            <MUI.TableCell align="left">Status</MUI.TableCell>
            <MUI.TableCell align="left">Data de início</MUI.TableCell>
            <MUI.TableCell align="left">Prazo</MUI.TableCell>
            <Permitted someTags={Object.values(permissionTagsConstants.TASKS)}>
              <MUI.TableCell align="center">Ação</MUI.TableCell>
            </Permitted>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(tasks) && (
            <TableRow>
              <MUI.TableCell align="left" colSpan={8}>
                {!loading && 'Nenhuma atividade encontrada'}
              </MUI.TableCell>
            </TableRow>
          )}
          {tasks?.map((task) => (
            <TableRow key={task.id}>
              <MUI.TableCell align="left">{task?.id}</MUI.TableCell>
              <MUI.TableCell align="left">{task?.company?.name}</MUI.TableCell>
              <Tooltip title={task?.name}>
                <MUI.TableCell align="left" $maxWidth={250}>
                  {task?.name}
                </MUI.TableCell>
              </Tooltip>
              <MUI.TableCell align="left">
                {task?.responsible?.name || '-'}
              </MUI.TableCell>
              <MUI.TableCell align="center" width={200}>
                <MUI.Chip
                  $width="90%"
                  label={taskHelper
                    .translateStatus(task?.status)
                    ?.toUpperCase()}
                  variant="filled"
                  size="small"
                  color={taskHelper.statusColor(task?.status)}
                  $borderRadius={21}
                  $color="white"
                />
              </MUI.TableCell>
              <MUI.TableCell align="left">
                {task?.startDate
                  ? formatters.date.ptBrFormatDate(task?.startDate)
                  : '-'}
              </MUI.TableCell>
              <MUI.TableCell align="left">
                {task?.dueDate
                  ? formatters.date.ptBrFormatDate(task?.dueDate)
                  : '-'}
              </MUI.TableCell>
              <MUI.TableCell align="center" width={50}>
                <Box display="flex">
                  <IconButton onClick={() => handleOpenView(task)}>
                    <EyeIcon />
                  </IconButton>
                  <Permitted
                    someTags={[
                      permissionTagsConstants.TASKS.CREATE,
                      permissionTagsConstants.TASKS.UPDATE,
                      permissionTagsConstants.TASKS.DESTROY,
                    ]}
                  >
                    <MenuButton id={`organization-task-${task.id}`}>
                      <Permitted
                        someTags={[
                          permissionTagsConstants.TASKS.CREATE,
                          permissionTagsConstants.TASKS.UPDATE,
                        ]}
                      >
                        <MenuItem onClick={() => handleOpenEdit(task)}>
                          EDITAR
                        </MenuItem>
                      </Permitted>
                      {task?.status === tasksConstants.PENDING_TASK && (
                        <Permitted tag={permissionTagsConstants.TASKS.DESTROY}>
                          <MenuItem onClick={() => handleDestroy(task)}>
                            EXCLUIR
                          </MenuItem>
                        </Permitted>
                      )}
                      <MenuItem onClick={() => getLogsTask(task.id)}>
                        HISTÓRICO
                      </MenuItem>
                    </MenuButton>
                  </Permitted>
                </Box>
              </MUI.TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableComponent>
    </TableContainer>
  )
}

export default TaskTable
