import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'

import {
  BigAvatar,
  ConditionalLoading,
  DefaultPageStructure,
  PageName,
  Permitted,
} from 'components'
import {
  ButtonTransparentLogin,
  CompanyDetails,
  CompanyStatusCard,
  ComplianceByPercentage,
  DataProcess,
  HealthScorePieChart,
  IdentifySelectionForm,
  IncidentStatsCard,
  NonComplianceReport,
  OrganizationCompanyDetails,
  Questionnaire,
  SecurityMeasure,
} from './components'

import { OrganizationCompanyType } from 'types/organizationCompany'
import { DataProcessStatsType } from 'types/dataProcess.types'

import { useAuth, useFetch, useOrganization } from 'hooks'

import service from 'service'
import {
  dashboard as dashboardHelper,
  suppliers as suppliersHelper,
} from 'helpers'
import theme from 'theme'
import routes from 'constants/routes'
import permissionTags from 'constants/permissionTags'

const CompanyUpdate = () => {
  const { organizationCompanyId } = useParams()
  const { user } = useAuth()
  const { currentOrganizationUser } = useOrganization()
  const navigate = useNavigate()

  const { response, loading, refresh } = useFetch(
    service.dponet.organizationCompany.get,
    {
      organizationCompanyId,
    }
  )

  const organizationId = response?.data?.organizationCompany?.organizationId

  const organizationCompany: OrganizationCompanyType =
    response?.data?.organizationCompany
  const company = organizationCompany?.company
  const companyId = company?.id

  const companyStats = company?.stats
  const questionnaireStats = companyStats?.questionnaire || []
  const dataProcessStats = companyStats?.dataProcess || []
  const supplierStats = companyStats?.supplier
  const questionControlStats = companyStats?.questionControl || {}
  const fragilityStats = companyStats?.fragility || {}

  const generalDataProcessStats: DataProcessStatsType =
    dashboardHelper.dataProcesses.general(dataProcessStats ?? [])
  const suppliers = supplierStats?.mySuppliers || []
  const complianceByQuestionnaires = questionnaireStats.map(
    (questionnaire) => ({
      name: questionnaire.name,
      gapCompliance: questionnaire.gapCompliance,
    })
  )

  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const isDpoFromCompany =
    organizationCompany?.company?.outsourcedDpo?.email === user?.email

  const handleReturn = () => navigate(routes.app.companies.all)

  const showButtonTransparentLogin =
    isDpoFromCompany || currentOrganizationUser?.clientPortalAccess

  return (
    <DefaultPageStructure>
      <PageName name="Empresa" onlyPageTitle />

      <ConditionalLoading loading={loading} centered height="100%">
        <Grid container spacing={4}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={2.5} lg={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={isExtraSmall ? 'center' : 'start'}
                pl={isExtraSmall ? 0 : 4}
              >
                <BigAvatar
                  alt={company?.name}
                  src={company?.logo || 'broken-image.png'}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={showButtonTransparentLogin ? 6.5 : 9.5}
              lg={showButtonTransparentLogin ? 7 : 10}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems={isExtraSmall ? 'center' : 'start'}
                height="100%"
              >
                <Typography variant="h5" component="h1" color="primary">
                  {company?.name}
                </Typography>
                <Typography variant="body1">{company?.document}</Typography>
              </Box>
            </Grid>
            {showButtonTransparentLogin && (
              <ButtonTransparentLogin companyId={company?.id} />
            )}
          </Grid>

          <Grid item xs={12} xl={8}>
            <CompanyDetails company={company} />
          </Grid>

          <Grid item xs={12} xl={4}>
            <OrganizationCompanyDetails
              organizationCompany={organizationCompany}
              refresh={refresh}
            />
          </Grid>

          <Permitted tag={permissionTags.CLIENT_PORTAL_THEME.MANAGE}>
            <Grid item xs={12}>
              <IdentifySelectionForm
                organizationId={organizationId}
                companyId={companyId}
              />
            </Grid>
          </Permitted>

          <Grid
            item
            xs={12}
            xl={8}
            display="grid"
            gridTemplateRows="auto 1fr"
            gap={4}
          >
            <HealthScorePieChart
              healthScore={Number(Number(company?.healthScore).toFixed())}
            />
            <IncidentStatsCard
              incidentRmcs={company?.stats?.general?.incidentRmcs}
            />
          </Grid>

          <Grid item xs={12} xl={4}>
            <CompanyStatusCard company={company} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Diagnóstico
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Questionnaire.ComplianceCard
              gapCompliance={companyStats?.general?.gapCompliance || 0}
              questionnaireStats={questionnaireStats}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Questionnaire.Progress questionnaires={questionnaireStats || []} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Questionnaire.ComplianceByQuestionnaire
              questionnairiesStats={complianceByQuestionnaires}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SecurityMeasure.StatusPieChart
              questionControlStats={questionControlStats}
            />
          </Grid>

          <Grid item xs={12} lg={8}>
            <SecurityMeasure.StatusByCategoryBarChart
              questionControlStats={questionControlStats}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SecurityMeasure.ByCategoryCard
              questionControlStats={questionControlStats}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SecurityMeasure.RisksPerSeverity fragilityStats={fragilityStats} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SecurityMeasure.RisksWithSecurityMeasure
              fragilityStats={fragilityStats}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SecurityMeasure.DepartmentsByThreats
              title="Departamentos por menor quantidade de ameaças"
              departments={fragilityStats.departments}
              orderByCriteria={['stats.totalCount']}
              orderDirection={['asc']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SecurityMeasure.DepartmentsByThreats
              title="Departamentos por maior quantidade de ameaças"
              departments={fragilityStats.departments}
              orderByCriteria={['stats.totalCount']}
              orderDirection={['desc']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SecurityMeasure.DepartmentsByThreats
              title="Departamentos por menor severidade de ameaças"
              departments={fragilityStats.departments}
              orderByCriteria={[
                'stats.severe',
                'stats.high',
                'stats.medium',
                'stats.low',
              ]}
              orderDirection={['asc', 'asc', 'asc', 'asc']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SecurityMeasure.DepartmentsByThreats
              title="Departamentos por maior severidade de ameaças"
              departments={fragilityStats.departments}
              orderByCriteria={[
                'stats.severe',
                'stats.high',
                'stats.medium',
                'stats.low',
              ]}
              orderDirection={['desc', 'desc', 'desc', 'desc']}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Processos
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <DataProcess.DetailsStatus
              generalDataProcessStats={generalDataProcessStats}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <DataProcess.DetailsRisks
              generalDataProcessStats={generalDataProcessStats}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <DataProcess.StatusPieChart
              generalDataProcessStats={generalDataProcessStats}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Incidentes
            </Typography>
          </Grid>

          <Grid item xs={12} lg={4}>
            <NonComplianceReport.DetailsStatus
              title="Incidentes"
              nonComplianceStats={companyStats?.general?.incidentRmcs}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Fornecedores
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ComplianceByPercentage
              title="Conformidade dos fornecedores"
              subtitle="Acompanhe o progresso dos fornecedores"
              zeroToTwenty={suppliersHelper.countSupplierByCompliance(
                suppliers,
                0,
                20
              )}
              twentyToForty={suppliersHelper.countSupplierByCompliance(
                suppliers,
                20,
                40
              )}
              fortyToSixty={suppliersHelper.countSupplierByCompliance(
                suppliers,
                40,
                60
              )}
              sixtyToEighty={suppliersHelper.countSupplierByCompliance(
                suppliers,
                60,
                80
              )}
              eightyToHundred={suppliersHelper.countSupplierByCompliance(
                suppliers,
                80,
                101
              )}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end" mt={4}>
            <Button variant="outlined" onClick={handleReturn}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      </ConditionalLoading>
    </DefaultPageStructure>
  )
}

export default CompanyUpdate
