import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreatError = api.create('dponet', false)

const me = async ({ params = {} }: { params: object }) =>
  await dponetAPI.get('/advisors/me', { params })

const identify = async () =>
  await dponetAPI.get('/advisors/organization_users/identify')

const permissions = async ({ params = {} }: { params?: object }) =>
  await dponetAPINotTreatError.get('/advisors/organization_users/permissions', {
    params,
  })

export default {
  me,
  identify,
  permissions,
}
