import { Fragment, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Hidden,
  Paper,
  Skeleton,
  Switch,
  TablePagination,
  Typography,
} from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { isNull, isEmpty } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  CompaniesAutocomplete,
  NoData,
  PaperHeader,
  BasicDialog as VinculateCompany,
  BasicDialog as ConfirmVinculation,
  Permitted,
} from 'components'
import { LinkedCompaniesTable } from './components'

import { AutoselectType, LinkedCompaniesType } from './linkedCompanies.types'

import service from 'service'
import { useFetch, useSnackbar } from 'hooks'
import schema, { CompaniesLinkingFormSchemaType } from './schema'
import permissionTags from 'constants/permissionTags'

const LinkedCompanies = ({
  dataProcessTemplate,
  form,
  refreshDataProcessTemplate,
}: LinkedCompaniesType) => {
  const [openSelectVinculateCompany, setVinculateCompany] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  const { snackbar } = useSnackbar()

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
  }

  const {
    control,
    watch,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = useForm<CompaniesLinkingFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationCompanies: [],
      allOrganizationCompanies: false,
    },
  })

  const organizationCompanies = watch('organizationCompanies') as
    | AutoselectType[]
    | null

  const allCompanies = watch('allOrganizationCompanies')

  const linkCompanies = async (data: CompaniesLinkingFormSchemaType) => {
    const companiesIds = data?.organizationCompanies?.map(
      (organizationCompany) => {
        return organizationCompany.companyId
      }
    )

    try {
      await service.dponet.dataProcessTemplateCompany.create({
        dataProcessTemplateId: dataProcessTemplate?.id,
        data: {
          dataProcessTemplateCompany: {
            allCompanies: data?.allOrganizationCompanies,
            companiesIds,
          },
        },
      })
      refresh()
      if (refreshDataProcessTemplate) {
        refreshDataProcessTemplate()
      }
      onCloseModal()
      snackbar.open({
        message: 'Vinculado com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao vincular empresas.',
        variant: 'error',
      })
    }
  }

  const onCloseModal = () => {
    setVinculateCompany(false)

    resetField('organizationCompanies')
    resetField('allOrganizationCompanies')
  }

  const { response, loading, refresh } = useFetch(
    service.dponet.dataProcessTemplateCompany.get,
    {
      dataProcessTemplateId: dataProcessTemplate?.id,
      params: {
        page,
        perPage,
      },
    },
    [page, perPage],
    form === 'edit'
  )

  const allOrganizationCompanies = watch('allOrganizationCompanies')

  const openConfirmationDialog = () => {
    trigger()

    if (!isValid) return

    setOpenConfirmation(true)
  }

  return (
    <Paper>
      <PaperHeader
        title="Empresas Vinculadas"
        actionButton={
          <Permitted tag={permissionTags.DATA_PROCESS_TEMPLATE.MANAGE}>
            <Button
              startIcon={<PlusCircleIcon />}
              variant="contained"
              color="primary"
              disabled={form === 'create'}
              onClick={() => setVinculateCompany(true)}
            >
              Vincular Empresa
            </Button>
          </Permitted>
        }
      />
      {loading && form === 'edit' ? (
        <Box padding={5}>
          <Skeleton height={200} variant="rounded" />
        </Box>
      ) : isEmpty(response?.data?.companies) ? (
        <NoData title="Nenhuma empresa vinculada." withoutPaper />
      ) : (
        <Fragment>
          <LinkedCompaniesTable
            linkedCompanies={response?.data?.companies}
            dataProcessTemplateId={dataProcessTemplate?.id}
            refresh={refresh}
          />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Fragment>
      )}
      <VinculateCompany
        open={openSelectVinculateCompany}
        title="Selecione as Empresas"
        onClose={onCloseModal}
        saveButtonName="Vincular"
        disabled={isNull(organizationCompanies) && !allCompanies}
        onSave={openConfirmationDialog}
      >
        <Box mb={2}>
          <Typography fontWeight={500} variant="body1">
            Atenção! Ao vincular esta empresa a um novo modelo, o vínculo com o
            modelo anterior será perdido.
          </Typography>
        </Box>
        <form id="link-companies" onSubmit={handleSubmit(linkCompanies)}>
          <Controller
            render={({ field }) => (
              <FormControl component={Box} width="fit-content">
                <FormControlLabel
                  control={<Switch {...field} checked={!!field.value} />}
                  label="Todas as empresas"
                />
              </FormControl>
            )}
            control={control}
            name="allOrganizationCompanies"
          />
          {!allOrganizationCompanies && (
            <Controller
              render={({ field: { ref, ...rest } }) => (
                <FormControl fullWidth>
                  <CompaniesAutocomplete
                    {...rest}
                    inputRef={ref}
                    error={!!errors?.organizationCompanies?.message}
                    helperText={errors?.organizationCompanies?.message}
                    placeholder="Selecione uma ou mais empresas"
                    label="Empresas"
                  />
                </FormControl>
              )}
              name="organizationCompanies"
              control={control}
            />
          )}
        </form>
      </VinculateCompany>
      <ConfirmVinculation
        open={openConfirmation && isValid}
        onClose={() => setOpenConfirmation(false)}
        title="Deseja confirmar o vínculo?"
        form="link-companies"
        saveButtonName="Confirmar"
        closeButtonName="Cancelar"
      >
        <Typography fontWeight={500}>
          Atenção! Ao vincular esta empresa a um novo modelo, o vínculo com o
          modelo anterior será perdido.
        </Typography>
      </ConfirmVinculation>
    </Paper>
  )
}

export default LinkedCompanies
