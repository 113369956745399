/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

import { Form } from './components'
import {
  DialogLogs,
  ListPageStructure,
  LoadingFeedback,
  PageHead,
} from 'components'

import {
  useConvertToFile,
  useFetch,
  useIncidentManegement,
  useSnackbar,
} from 'hooks'

import { IncidentsType, IncidentManegementStep } from 'types/incidents.types'
import { LogsType } from 'types/logs.types'

import service from 'service'
import incidentManegementConstants from 'constants/incidentManegement'
import { formatters } from 'helpers'

const IncidentManegementsEdit = () => {
  const [openHistory, setOpenHistory] = useState(false)
  const [logsIncident, setLogsIncident] = useState<LogsType[]>([])
  const [logsLoading, setLogsLoading] = useState(false)

  const { incidentId } = useParams()
  const { setActiveStep, incident, setIncident } = useIncidentManegement()
  const { snackbar } = useSnackbar()

  const { file: attachmentsFile, loading: attachmentsFileLoading } =
    useConvertToFile(incident?.attachments)

  const {
    fileList: detectionAttachmentsList,
    loading: detectionAttachmentsListLoading,
  } = useConvertToFile(incident?.detectionAttachments)

  const {
    file: notificationOpinionAttachmentFile,
    loading: notificationOpinionAttachmentFileLoading,
  } = useConvertToFile(incident?.notificationOpinionAttachment)

  const loadingFiles =
    attachmentsFileLoading ||
    detectionAttachmentsListLoading ||
    notificationOpinionAttachmentFileLoading

  const {
    response,
    loading: incidentLoading,
    refresh,
  } = useFetch(service.dponet.incidents.get, {
    incidentId,
  })

  const incidentResponse: IncidentsType = response?.data?.incident

  const identifyCurrentStep = (
    incidentManegementSteps: IncidentManegementStep[]
  ) => {
    const stepsByEnum = incidentManegementConstants.STEPS_BY_ENUM

    incidentManegementSteps.forEach((incidentManegementStep) => {
      incidentManegementStep.order = stepsByEnum[incidentManegementStep.step]
    })

    incidentManegementSteps = incidentManegementSteps.sort((a, b) => {
      if (!a?.order || !b?.order) return 0

      return a?.order - b?.order
    })

    const firstUnconcludedStep = incidentManegementSteps.find(
      (incidentManegementStep) => !incidentManegementStep.concluded
    )

    if (isEmpty(firstUnconcludedStep))
      return setActiveStep(stepsByEnum['lessons_learned'])

    if (firstUnconcludedStep.order) setActiveStep(firstUnconcludedStep.order)
  }

  useEffect(() => {
    if (incidentResponse) {
      if (isEmpty(incident)) {
        identifyCurrentStep(incidentResponse.incidentManegementSteps)
      }

      setIncident(incidentResponse)
    }
  }, [incidentResponse])

  useEffect(() => {
    if (!isEmpty(incident) && !loadingFiles) {
      setIncident(
        (prevIncident) =>
          ({
            ...prevIncident,
            ...(!detectionAttachmentsListLoading && {
              detectionAttachmentsList,
            }),
            ...(!attachmentsFileLoading && { attachmentsFile }),
            ...(!notificationOpinionAttachmentFileLoading && {
              notificationOpinionAttachmentFile,
            }),
          }) as IncidentsType
      )
    }
  }, [
    detectionAttachmentsList,
    attachmentsFile,
    notificationOpinionAttachmentFile,
    loadingFiles,
  ])

  const getLogsIncident = async () => {
    try {
      setLogsLoading(true)
      const response = await service.dponet.incidents.getLogs({
        incidentId,
      })
      setLogsIncident(response?.data?.dponetAudits)
      setOpenHistory(true)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao buscar os logs.',
        variant: 'error',
      })
    } finally {
      setLogsLoading(false)
    }
  }

  return (
    <ListPageStructure>
      <LoadingFeedback open={incidentLoading || logsLoading} />
      <PageHead
        name="Editar Incidente"
        actionButton={
          <Button variant="outlined" onClick={getLogsIncident}>
            HISTÓRICO
          </Button>
        }
      />
      {incident && <Form incident={incident} refresh={refresh} />}
      <DialogLogs
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        logs={logsIncident}
        showRegisterSession
      />
    </ListPageStructure>
  )
}

export default IncidentManegementsEdit
