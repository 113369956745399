import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPIWithoutTreat = api.create('dponet', false)

const get = ({
  taskId = '',
  params = {},
}: {
  taskId?: string | number
  params?: object
}) => {
  return dponetAPI.get(`/advisors/tasks/${taskId}`, {
    params,
  })
}

const create = ({
  companyId = '',
  task = {},
}: {
  companyId?: string | number
  task?: object
}) => {
  return dponetAPIWithoutTreat.post(`/advisors/tasks`, {
    companyId,
    task,
  })
}

const update = ({
  taskId = '',
  task = {},
}: {
  taskId?: string | number
  task?: object
}) => {
  return dponetAPIWithoutTreat.put(`/advisors/tasks/${taskId}`, {
    task,
  })
}

const destroy = (taskId?: string | number) => {
  return dponetAPIWithoutTreat.delete(`/advisors/tasks/${taskId}`)
}

const getLogs = async ({ taskId }: { taskId?: number | string }) => {
  return dponetAPI.get(`/advisors/tasks/${taskId}/logs`)
}

const tasks = {
  create,
  destroy,
  get,
  getLogs,
  update,
}

export default tasks
