import { useState } from 'react'
import { Grid, Skeleton, Box, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import {
  DefaultPageStructure,
  DialogLogs,
  LoadingFeedback,
  PageHead,
} from 'components'
import { Form, Mapping, LinkedCompanies } from './components'

import { DataProcessTemplateType } from 'types/dataProcessTemplate.types'

import { useFetch, useSnackbar } from 'hooks'

import routes from 'constants/routes'
import service from 'service'
import { LogsType } from 'types/logs.types'
import { formatters } from 'helpers'

const DataProcessTemplateEdit = () => {
  const [openHistory, setOpenHistory] = useState(false)
  const [logsDataProcessTemplate, setLogsDataProcessTemplate] = useState<
    LogsType[]
  >([])
  const [logsLoading, setLogsLoading] = useState(false)

  const { dataProcessTemplateId } = useParams()
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const { response, loading, refresh } = useFetch(
    service.dponet.dataProcessTemplate.get,
    {
      dataProcessTemplateId,
      params: {
        minimal: true,
      },
    }
  )

  const getLogsDataProcessTemplate = async () => {
    try {
      setLogsLoading(true)
      const response = await service.dponet.dataProcessTemplate.getLogs({
        dataProcessTemplateId,
      })
      setLogsDataProcessTemplate(response?.data?.dponetAudits)
      setOpenHistory(true)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao buscar os logs.',
        variant: 'error',
      })
    } finally {
      setLogsLoading(false)
    }
  }

  const dataProcessTemplate: DataProcessTemplateType =
    response?.data?.dataProcessTemplate

  const handleReturn = () => navigate(routes.app.dataProcesses.templates.all)
  const handleCloseHistory = () => setOpenHistory(false)

  return (
    <DefaultPageStructure>
      <PageHead
        name="Edição do Modelo"
        actionButton={
          <Button variant="outlined" onClick={getLogsDataProcessTemplate}>
            HISTÓRICO
          </Button>
        }
      />
      <LoadingFeedback open={logsLoading} />

      {loading ? (
        <Box gap={4} display="flex" flexDirection="column">
          <Skeleton height={200} variant="rounded" />
          <Skeleton height={200} variant="rounded" />
          <Skeleton height={200} variant="rounded" />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Form dataProcessTemplate={dataProcessTemplate} form="edit" />
          </Grid>

          <Grid item xs={12}>
            <Mapping dataProcessTemplate={dataProcessTemplate} form="edit" />
          </Grid>

          <Grid item xs={12}>
            <LinkedCompanies
              dataProcessTemplate={dataProcessTemplate}
              form="edit"
              refreshDataProcessTemplate={refresh}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="end">
            <Button variant="outlined" onClick={handleReturn}>
              Voltar
            </Button>
          </Grid>
        </Grid>
      )}
      <DialogLogs
        open={openHistory}
        onClose={handleCloseHistory}
        logs={logsDataProcessTemplate}
        showRegisterSession
      />
    </DefaultPageStructure>
  )
}

export default DataProcessTemplateEdit
