import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Check as CheckIcon,
  File as FileIcon,
  Moon as MoonIcon,
} from 'react-feather'
import { Box, Button, Grid, colors } from '@mui/material'

import {
  DialogLogs,
  ListPageStructure,
  LoadingFeedback,
  PageHead,
} from 'components'
import {
  GeneralInformationCard,
  LinkedCompaniesTable,
  QuestionInfoCard,
  QuestionTable,
} from './components'

import { useFetch, usePermission, useSnackbar } from 'hooks'

import { QuestionnaireType } from 'types/questionnaire'

import service from 'service'
import constants from 'constants/index'
import routes from 'constants/routes'
import { LogsType } from 'types/logs.types'
import { formatters } from 'helpers'

const QuestionnairesEdit = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [disableQuestionnaire, setDisableQuestionnaire] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [logsQuisTemplates, setLogsQuisTemplates] = useState<LogsType[]>([])
  const [logsLoading, setLogsLoading] = useState(false)

  const { questionnaireId } = useParams()
  const { isPermitted } = usePermission()
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const {
    response: questionnaireResponse,
    loading: loadingQuestionnaire,
    refresh,
  } = useFetch(
    service.dponet.questionnaires.show,
    {
      quizTemplateId: questionnaireId,
    },
    []
  )

  const getLogsQuestionnaires = async () => {
    try {
      setLogsLoading(true)
      const response = await service.dponet.questionnaires.getLogs({
        quizTemplateId: questionnaireId,
      })
      setLogsQuisTemplates(response?.data?.dponetAudits)
      setOpenHistory(true)
    } catch (error) {
      snackbar.open({
        message:
          formatters.errorMessage(error) ||
          'Ocorreu um erro ao buscar os logs.',
        variant: 'error',
      })
    } finally {
      setLogsLoading(false)
    }
  }

  const questionnaire: QuestionnaireType =
    questionnaireResponse?.data?.quizTemplate

  const isPermittedManage = isPermitted(
    constants.permissionTags.QUESTIONNAIRES.MANAGE
  )

  const handleReturn = () => navigate(routes.app.questionnaires.all)
  const handleCloseHistory = () => setOpenHistory(false)

  return (
    <ListPageStructure>
      <LoadingFeedback
        open={isLoading || loadingQuestionnaire || logsLoading}
      />
      <PageHead
        name="Edição de questionários"
        actionButton={
          <Button variant="outlined" onClick={getLogsQuestionnaires}>
            HISTÓRICO
          </Button>
        }
      />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Todas as Questões"
                value={questionnaire?.questionsCount}
                icon={<FileIcon color="white" size={20} />}
                boxColor={colors.blueGrey[900]}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Ativa"
                value={questionnaire?.activeQuestionsCount}
                icon={<CheckIcon color="white" size={20} />}
                boxColor={colors.green[500]}
              />
            </Grid>
            <Grid item xs={12} lg={4} xl={2.3}>
              <QuestionInfoCard
                title="Inativas"
                value={questionnaire?.inactiveQuestionsCount}
                icon={<MoonIcon color="white" size={20} />}
                boxColor={colors.blueGrey[400]}
              />
            </Grid>
          </Grid>
        </Grid>
        {!loadingQuestionnaire && (
          <Grid item xs={12}>
            <GeneralInformationCard
              setIsLoading={setIsLoading}
              questionnaireId={questionnaireId}
              questionnaire={questionnaire}
              refreshQuestionnaire={refresh}
              disableQuestionnaire={disableQuestionnaire}
              disableManagement={!isPermittedManage}
              disableDefault={questionnaire?.default}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LinkedCompaniesTable
            disableManagement={!isPermittedManage}
            questionnaire={questionnaire}
            questionnaireId={questionnaireId}
            setIsLoading={setIsLoading}
            refreshQuestionnaire={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <QuestionTable
            disableManagement={!isPermittedManage}
            questionnaireId={questionnaireId}
            setDisableQuestionnaire={setDisableQuestionnaire}
            setIsLoading={setIsLoading}
            refreshQuestionnaire={refresh}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="end" mt={4}>
        <Button variant="outlined" onClick={handleReturn}>
          Voltar
        </Button>
      </Box>
      {!logsLoading && (
        <DialogLogs
          open={openHistory}
          onClose={handleCloseHistory}
          logs={logsQuisTemplates}
          showRegisterSession
        />
      )}
    </ListPageStructure>
  )
}

export default QuestionnairesEdit
