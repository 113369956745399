import { isUndefined } from 'lodash'
import moment from 'moment'

import { QuestionsDpoFormType } from 'views/LegitimateInterest/components/QuestionsDpo/schema'
import { AnswersLiaReviewFormType } from 'views/LegitimateInterest/schemaAnswersAnalysis'
import constants from 'constants/index'

const mountAnalysisLiaAnswersData = (data: AnswersLiaReviewFormType) => {
  const mountedData = []

  for (const key in data) {
    if (key.startsWith('order')) {
      const order = key.replace('order', '')
      const answerDpoKey = `answerDpoQuestion${order}`
      const reasonRejection =
        data[answerDpoKey as keyof AnswersLiaReviewFormType]
      const approvedAnswerKey = `answerDpoQuestion${order}WasApproved`
      const approvedAnswer =
        data[approvedAnswerKey as keyof AnswersLiaReviewFormType]

      if (isUndefined(order)) continue

      const liaAnswerAnalysis = {
        order,
        approvedAnswer,
        reasonRejection,
      }

      mountedData.push(liaAnswerAnalysis)
    }
  }

  return mountedData
}

const mountDpoReplyLia = (
  data: QuestionsDpoFormType,
  activeStep: number,
  legalFramworksName?: string[]
) => {
  const {
    LEGAL_FRAMEWORKS_LIA,
    QUESTIONS_POSSIBLE_USE_DATA_TREATMENT,
    STEPS_DPO,
  } = constants.legitimateInterest
  const { FRAUD_PREVENTION, LEGITIMATE_INTEREST } = LEGAL_FRAMEWORKS_LIA
  const { QUESTION_LEGITIMATE_INTEREST, QUESTION_PREVENT_FRAUD } =
    QUESTIONS_POSSIBLE_USE_DATA_TREATMENT

  const mountDpoReply = []
  for (const key in data) {
    if (key.startsWith('questionDpo')) {
      const order = key.replace('questionDpo', '')

      // indexação por chave para a pegar a resposta no objeto
      const answer = data[key as keyof QuestionsDpoFormType]

      const dpoReply = { order, answer }
      const orderToInt = parseInt(order)

      // variavel vai ser true dependendo se a ordem da questão for 37
      // mas o processo não tem enquadramento legal legitimo interesse
      const questionLegimateInterestWithoutNecessaryLegalFramework =
        orderToInt === QUESTION_LEGITIMATE_INTEREST &&
        !legalFramworksName?.includes(LEGITIMATE_INTEREST)

      // variavel vai ser true dependendo se a ordem da questão for 41
      // mas o processo não tem enquadramento prevenção a fraude
      const questionPreventFraudeWithoutNecessaryLegalFramework =
        orderToInt === QUESTION_PREVENT_FRAUD &&
        !legalFramworksName?.includes(FRAUD_PREVENTION)

      // Se o step atual estiver em conclusão mas a ordem da questão for maior
      // que 36 ela não entrará nas respostas enviadas para api
      // e se a variaveis questionLegimateInterestWithoutNecessaryLegalFramework
      // ou questionPreventFraudeWithoutNecessaryLegalFramework
      // forem verdadeiras a ordem e a resposta também não serão enviadas para api
      if (
        (activeStep < STEPS_DPO.CONCLUSION &&
          orderToInt >= QUESTION_LEGITIMATE_INTEREST) ||
        questionLegimateInterestWithoutNecessaryLegalFramework ||
        questionPreventFraudeWithoutNecessaryLegalFramework
      ) {
        continue
      }

      mountDpoReply.push(dpoReply)
    }
  }
  return mountDpoReply
}

const formatAnswerField = (value: string | number) => {
  const regexDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/
  // Regex para datas no formato ISO 8601

  const match = value.toString().match(regexDate)

  if (match) {
    return moment(value).format('DD/MM/YYYY [às] HH:mm')
  }

  if (value === 'yes') return 'Sim'
  if (value === 'no') return 'Não'

  return value
}

export default {
  mountAnalysisLiaAnswersData,
  mountDpoReplyLia,
  formatAnswerField,
}
