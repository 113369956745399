import { Fragment, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { CompaniesAutocomplete } from 'components'

import { useFetch, useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { CompaniesLinkingFormType } from './companiesLinkingForm.types'
import { AutoselectType } from 'components/CompanyAutocomplete/companyAutocomplete.types'
import { TagMinimumType } from 'types/tag.types'

import schema, { CompaniesLinkingFormSchemaType } from './schema'
import service from 'service'

const CompaniesLinkingForm = ({
  setIsLoading,
  onClose,
  refresh,
  refreshQuestionnaire,
  formName,
  questionnaireId,
}: CompaniesLinkingFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CompaniesLinkingFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      allOrganizationCompanies: false,
      organizationCompanies: null,
      allClassifiers: false,
      classifiers: [],
    },
  })

  const allOrganizationCompanies = watch('allOrganizationCompanies')
  const allClassifiers = watch('allClassifiers')

  const { response: responseTags, loading: loadingTags } = useFetch(
    service.dponet.tags.get,
    {
      params: {
        perPage: 999,
        minimal: true,
      },
    },
    []
  )

  const organizationTags: TagMinimumType[] =
    responseTags?.data?.organizationTags || []

  const organizationCompaniesId = (
    organizationCompanies?: AutoselectType[] | null
  ) => {
    return (
      organizationCompanies?.map(({ companyId }) => ({ id: companyId })) || []
    )
  }

  const onSubmit = async (data: CompaniesLinkingFormSchemaType) => {
    try {
      setIsLoading(true)
      const formData = {
        allOrganizationCompanies: data?.allOrganizationCompanies,
        organizationCompanies: organizationCompaniesId(
          data?.organizationCompanies
        ),
        classifiers: data?.classifiers,
      }
      await service.dponet.questionnaires.linkCompanies({
        quizTemplateId: questionnaireId,
        quizTemplate: {
          ...formData,
        },
      })
      snackbar.open({
        message: 'Alterações aplicadas com sucesso!',
        variant: 'success',
      })
      reset()
      refreshQuestionnaire()
      refresh()
      onClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadingTags ? setIsLoading(true) : setIsLoading(false)
  }, [loadingTags])

  useEffect(() => {
    if (allClassifiers) {
      setValue('organizationCompanies', null)
    } else {
      setValue('classifiers', [])
    }
  }, [allClassifiers])

  useEffect(() => {
    if (allOrganizationCompanies) {
      setValue('allClassifiers', false)
      setValue('classifiers', [])
      setValue('organizationCompanies', null)
    }
  }, [allOrganizationCompanies])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Controller
        render={({ field }) => (
          <FormControl component={Box} width="fit-content">
            <FormControlLabel
              control={<Switch {...field} checked={!!field.value} />}
              label="Todas as empresas"
            />
          </FormControl>
        )}
        control={control}
        name="allOrganizationCompanies"
      />
      {!allOrganizationCompanies && (
        <Fragment>
          {!allClassifiers && (
            <Controller
              render={({ field: { ref, ...rest } }) => (
                <FormControl fullWidth>
                  <CompaniesAutocomplete
                    {...rest}
                    inputRef={ref}
                    error={!!errors?.organizationCompanies?.message}
                    helperText={errors?.organizationCompanies?.message}
                    isOrganizationCompany
                    placeholder="Selecione uma ou mais empresas"
                    label="Empresas"
                  />
                </FormControl>
              )}
              name="organizationCompanies"
              control={control}
            />
          )}
          {allClassifiers && (
            <Controller
              render={({ field }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    {...field}
                    multiple
                    options={organizationTags}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors?.classifiers}
                        helperText={errors?.classifiers?.message}
                        margin="dense"
                        variant="outlined"
                        label="Classificador"
                        placeholder="Selecione o classificador"
                        fullWidth
                        InputLabelProps={{
                          children: null,
                        }}
                      />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                </FormControl>
              )}
              control={control}
              name="classifiers"
            />
          )}
          <Controller
            render={({ field }) => (
              <FormControl component={Box} width="fit-content">
                <FormControlLabel
                  control={<Switch {...field} checked={!!field.value} />}
                  label="Selecionar todas as empresas por classificadores"
                />
              </FormControl>
            )}
            control={control}
            name="allClassifiers"
          />
        </Fragment>
      )}
    </Box>
  )
}

export default CompaniesLinkingForm
